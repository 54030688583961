<template>
  <tr>
    <th scope="row">
      <span class="shekel" v-text="'₪'" />
      {{ product.price }}
    </th>

    <th>
      <SpinButton :product="product" />
      <p class="cartErr" v-if="errorQuantity.prodID === product.amount_id" v-text="errorQuantity.msg" />
    </th>

    <td v-text="product.sizeID.title" />

    <td v-text="product.colorID.title" />

    <td class="td-description">
      <router-link :to="getRoute" v-text="product.title" />
    </td>

    <td>
      <router-link :to="getRoute" class="cart-item-img-link">
        <img :src="image" :alt="product.title" />
      </router-link>
    </td>

    <td>
      <button class="btn btn-delete-from-cart" @click.prevent="deleteFromCart(product)" v-text="'הסר מוצר'" />
    </td>
  </tr>
</template>

<script>
import cartMixin from '@/mixins/cart.mixin'
import SpinButton from '@/components/ui/SpinButton'

export default {
  name: 'CartItemTr',

  components: {
    SpinButton
  },

  mixins: [cartMixin],

  props: ['product'],

  computed: {
    errorQuantity() {
      return this.$store.getters.errorQuantity
    },

    image() {
      return process.env.VUE_APP_IMAGE_URL + '/'+ this.product.img
    },

    getRoute() {
      return {
        name: 'product',
        params: {
          subCategoryId: 0,
          productId: this.product.id
        }
      }
    }
  }
}
</script>

<style scoped>
.cartErr{
  margin-bottom: -30px;
}
.cart-item-img-link{
  display: block;
}
</style>
