<template>
  <div class="small-cart">
    <div class="small-cart-body">
      <CardProductOrder
        v-for="(product, index) of cart.products"
        :key="index"
        :product="product"
        :changeQuantity="true"
        :canDelete="true"
      />
    </div>
  </div>
</template>

<script>
import CardProductOrder from '@/components/CardProductOrder'

export default {
  name: 'CartMobile',

  components: {
    CardProductOrder
  },

  props: ['cart']
}
</script>

<style scoped>
.small-cart{
  border-right-width: 0;
  border-bottom-width: 0;
}
.small-cart-body{
  direction: rtl;
}
</style>
