<template>
  <div class="table-responsive">
    <table class="table">
      <thead>
      <tr>
        <th scope="col" v-text="'מחיר'" />
        <th scope="col" v-text="'כמות'" />
        <th scope="col" v-text="'מידה'" />
        <th scope="col" v-text="'צבע'" />
        <th scope="col" v-text="'שם הפריט'" />
        <th scope="col" />
        <th scope="col" />
      </tr>
      </thead>
      <tbody>
        <CartItemTr
          v-for="(product, index) of cart.products"
          :key="index"
          :product="product"
        />
      </tbody>
    </table>
  </div>
</template>

<script>
import CartItemTr from '@/components/cart/CartItemTr'

export default {
  name: 'CartDesktop',

  components: {
    CartItemTr
  },

  props: ['cart']
}
</script>
