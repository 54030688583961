<template>
  <div id="cart">
    <section class="site-section">
      <div class="container-fluid">

        <div class="section-header">
          <h2 class="section-title" v-text="'סל קניות'" />
        </div>

        <Loading v-if="loading" />

        <div class="section-body" v-else>
          <div class="row">
            <div class="col-md-3">
              <div class="cart-total">
                <div class="cart-total-body">
                  <div class="cart-checkout intermediate-summary">
                    <p><span class="shekel" v-text="'₪'" /> {{allPrice}}</p>
                    <p>סיכום ביניים ({{allQuantity}} מוצרים)</p>
                  </div>

                  <div class="cart-checkout total-payment">
                    <p><span class="shekel" v-text="'₪'" /> {{allPrice}}</p>
                    <p>סה"כ לתשלום</p>
                  </div>

                  <div v-if="blackFriday" class="cart-banner" />
                </div>

                <div class="cart-total-footer">
                  <a v-if="cart.products && cart.products.length" @click.prevent="goToCheckout" href="#" class="btn btn-primary" v-text="'המשך לתשלום'" />
                  <a href="#" @click.prevent="goBack" class="btn btn-outline-primary" v-text="'חזרה לחנות'" />
                </div>
              </div>
            </div>

            <div class="col-md-9">
              <div class="cart-content" v-if="cart.products && cart.products.length">
                <CartDesktop :cart="cart" v-if="device.desktop()" />

                <CartMobile :cart="cart" v-if="device.mobile()" />
              </div>

              <div class="cart-empty" v-else>
                <p v-text="'Your cart is empty'" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import device from 'current-device'
import cartMixin from '@/mixins/cart.mixin'
import checkoutMixin from '@/mixins/checkout.mixin'
import CartDesktop from '@/components/cart/CartDesktop'
import CartMobile from '@/components/cart/CartMobile'

export default {
  name: 'Cart',

  components: {
    CartDesktop,
    CartMobile
  },

  mixins: [cartMixin, checkoutMixin],

  metaInfo() {
    return {
      title: process.env.VUE_APP_NAME + ' | סל קניות | ' + process.env.VUE_APP_NAME_HEB
    }
  },

  data() {
    return {
      device,
      blackFriday: false
    }
  },

  computed: {
    cart() {
      return this.$store.state.cart.cart
    },

    loading() {
      return this.$store.getters.loading
    }
  },

  methods: {
    goBack() {
      this.$router.back()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/mixins/_breakpoints.scss';

#cart{
  margin-bottom: 17rem;
}
#cart .site-section .container-fluid{
  width: inherit;
  margin-right: 2.8rem;
  margin-left: 2.8rem;
}
.section-header{
  margin-bottom: 1.2rem;
}
.cart-total{
  border-right: 1px solid #D7D7D7;
  padding-right: 1.2rem;
  display: flex;
  flex-direction: column;
  height: 250px;
}
.cart-total-body{
  flex: 1 1 auto;
}
.cart-total-body p{
  font-size: 1.1rem;
}
.cart-content img{
  width: 7rem;
}
#cart .cart-total-footer .btn{
  border: 1px solid #000;
  font-family: 'Varela Round', sans-serif;
}
.td-description{
  font-size: .9rem;
}
@include media-breakpoint-down(sm) {
  #cart{
    margin-bottom: 2rem;
  }
  .site-section{
    margin-top: 1.4rem;
  }
  #cart .site-section .container-fluid{
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
  #innerLayout #cart .section-title {
    font-size: 1.3rem;
  }
  .section-header{
    margin-bottom: .9rem;
  }
  #cart .section-body .row{
    flex-direction: column-reverse;
  }
  .cart-total{
    padding-right: 1rem;
    padding-left: 1.9rem;
    padding-top: 1rem;
    height: inherit;
    border-right: none;
  }
  .cart-total-body p{
    font-size: .8rem;
    margin-bottom: 0;
  }
  .cart-checkout{
    margin-bottom: .5rem;
  }
  .cart-total-body{
    margin-bottom: .5rem;
  }
  #cart .cart-total-footer .btn{
    flex-grow: 1;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    font-size: .8rem;
  }
  #cart .cart-total-footer .btn:first-child{
    margin-right: .3rem;
  }
  #cart .cart-total-footer .btn:last-child{
    margin-left: .3rem;
  }
}
</style>
